/* *,*:hover{transition-duration: 0.5s;} */
@font-face {
    font-family: "ARCADECLASSIC";
    src: url(../src/assets/Fonts/ARCADECLASSIC.woff);
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: url(../src/assets/Fonts/Poppins-Regular.woff);
  }
  @font-face {
    font-family: "Poppins-Medium";
    src: url(../src/assets/Fonts/Poppins-Medium.woff);
  }
  @font-face {
    font-family: "Poppins-SemiBold";
    src: url(../src/assets/Fonts/Poppins-SemiBold.woff);
  }
  @font-face {
    font-family: "Poppins-Light";
    src: url(../src/assets/Fonts/Poppins-Light.woff);
  }

* {
  color: #fff;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=date] {
  background-image: url('./assets/Images/calender.png');
  background-repeat: no-repeat;
}
input[type=time] {
  background-image: url('./assets/Images/clock.png');
  background-repeat: no-repeat;
}
